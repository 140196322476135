import React from "react";

function Currency({ token, chosenPayment }) {
  return (
    <div
      className="currency-borders mt-1"
      style={{ backgroundColor: token == chosenPayment && "#faeede" }}
    >
      <div className="row">
        <div className="col-12">
          <span style={{ marginRight: "3px" }}>
            <img
              src={token.image}
              alt=""
              style={{ borderRadius: "50%", width: "25px" }}
            />
          </span>
          {token.symbol}
        </div>
      </div>
    </div>
  );
}

export default Currency;
